main {
    .filter-bar {
        height: 60px;
        display: flex;
        align-items: center;
    }
    .main-content {
        height: calc(100vh - 172px);
        background-color: white;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);

        &.has-filter-bar {
            height: calc(100vh - 172px - 60px);
        }
        &.fix-2 {
            height: calc(100vh - 122px);
        }
        &.fix-3 {
            height: calc(100vh - 192px);
        }
        .table-wrapper {
            height: 100%;
            overflow-y: auto;
            &.no-pagination {
                height: 100%;
            }
        }
    }
}
table {
    width: 100%;
    /* Set header to stick to the top of the container. */
    thead tr th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        vertical-align: top;
        border-bottom: none;
        color: white;
    }
    th {
        padding: 8.5px 0 8.5px 20px;
        background-color: #6F319F;
        // box-shadow: 0px 0px 0 2px #e8e8e8;
    }
    td {
        padding: 10px 0 10px 20px;
        border-bottom: 1px solid #ebebeb;
    }
}

/* Track */
::-webkit-scrollbar-thumb {
    background: #a5a5a5;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #807e7e;
}
