main {
    height: calc(100vh - 50px);
    .main-ctn {
        max-height: 100%;
        overflow-y: auto;
        background-color: #ededed;
    }
    @media (min-width: 768px) {
        .sidebar-ctn {
            flex: 0 0 15%;
            max-width: 15%;
        }
        .main-ctn {
            flex: 0 0 85%;
            max-width: 85%;
            padding: 16px;
        }
    }
    @media (max-width: 767px) {
        .sidebar-ctn {
            display: none;
        }
        .main-ctn {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
.action-icon {
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    margin-right: 2px;
    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}
.form-control {
    min-height: 40px;
}
.multiselect {
    border-radius: 5px;
    .multiselect__tags {
        border: 1px solid #ced4da;
    }
}
img.contain {
    object-fit: contain;
}
.icon-bg-purple {
    background-color: #6F319F;
}
.campaign-category-selector {
    .multiselect__tags {
        max-height: 40px;
    }
    .multiselect__placeholder {
        padding-top: 0;
        padding-left: 2px;
        color: #35495e;
        opacity: 0.8;
        font-size: 1rem;
    }
}
.min-w-260 {
    min-width: 260px;
}
.period_separator {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.max-w-200px {
    max-width: 200px;
}
.max-w-400px {
    max-width: 400px;
}

.avatar-rounded {
    height: 70px;
    width: 70px;
    img {
        border: 1px solid #ededed;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: contain;
    }
}

.flex-200px {
    flex: 0 0 200px;
    max-width: 200px;
}
.ck-editor__editable {
    min-height: 140px;
}

input[type='search'].search-input {
    width: 300px;
}
.search-button {
    height: 40px;
}
.new-item-link {
    min-width: 200px;
}
.group-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #b6bdcb;
    text-transform: uppercase;
    padding-bottom: 12px;
}
.highlight-text {
    color: $primary;
    text-decoration: underline;
}
.clickable {
    cursor: pointer;
}
