// Bootstrap
@import '~bootstrap/scss/bootstrap';

// BootstrapVue Custom SCSS ---
@import '~bootstrap-vue/src/index.scss';

@import '~vue-multiselect/dist/vue-multiselect.min.css';

@import "common";
@import "layouts";
